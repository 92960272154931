import React from 'react';
import { Badge, Flex } from 'antd';

const stylesCard = {
    borderRadius: '10px',
    border: '1px solid #0084E4',
    borderLeft: '10px solid #0084E4',
    boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    padding: '0px 10px 0px 10px',
    margin: '0px',
    maxWidth: '100%',
}

const StatusTicket = (props) => {
    const { status } = props;
    if (status === 'Open') {
        return <Badge count={'Open'} color='#0084E4' />
    }
    else if (status === 'In progress') {
        return <Badge count={'In progress'} color='#FAAD14' />
    }
    else if (status === 'Resolve') {
        return <Badge count={'Resolve'} color='#00BD6E' />
    }
    else if (status === 'Closed') {
        return <Badge count={'Closed'} color='#8F8F8F' />
    }
}

const CardTicket = (props) => {
    const { message, ticketNo, updateTime, statusTicket, detailTicket } = props;

    return (
        <div style={stylesCard}>
            <Flex justify='space-between' align='center'>
                <p>Ticket #{ticketNo}</p>
                <StatusTicket status={statusTicket} />
            </Flex>
            <Flex vertical style={{ borderTop: '1px solid #0084E4' }}>
                <p style={{ margin: '10px 0px 5px' }}>{message}</p>
                <p
                    style={{
                        maxWidth: '80%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: '14px',
                        margin: '0px',
                        whiteSpace: 'nowrap'
                    }}
                >{detailTicket}
                </p>
            </Flex>
            <Flex justify='end'>
                <p style={{ color: 'gray', fontSize: '14px', margin: '5px 0px' }}>Last update: {updateTime}</p>
            </Flex>
        </div>
    );

}

export default CardTicket;

