import React from 'react';

import {
  Space,
  Form,
  Select,
  Input,
  Upload,
  Button,
  message,
  ConfigProvider,
  Flex
} from 'antd';

import { UploadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ProfileUser from '../components/profileUser';

const { TextArea } = Input;

const stylesContent = {
  borderRadius: '10px',
  backgroundColor: '#fff',
  padding: '15px 20px 10px 20px',
  boxShadow: '10px 5px 10px rgba(0, 0, 0, 0.1)',
  margin: '5px 20px 20px 20px',
}

const props = {
  name: 'filename',
  action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const RequestPage = () => {
  return (
    <Flex justify='center'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'stretch',
          overflow: 'hidden',
          flexDirection: 'column',
          minWidth: 'fit-content',
          width: '600px',
        }}
      >
        <ProfileUser/>
        <div style={stylesContent}>
          <div style={{ textAlign: 'center' }}>
            <img src='Logo_DigitalFlow.png' alt='Logo' style={{ width: 100, height: 100 }} />
            <h3>แจ้งปัญหาการใช้งาน</h3>
          </div>
          <Form
            layout='vertical'
            initialValues={{ remember: true }}
          >
            <Form.Item
              label='หัวข้อ'
              name='subject_problem'
              rules={[{ required: true, message: 'กรุณาเลือกหัวข้อปัญหา' }]}
            >
              <Select
                placeholder="หัวข้อปัญหา"
                allowClear
                options={[
                  {
                    value: '1',
                    label: 'ปัญหาการเข้าสู่ระบบ'
                  },
                  {
                    value: '2',
                    label: 'ปัญหาการลงนามเอกสาร'
                  },
                  {
                    value: '3',
                    label: 'ปัญหาการสร้างเอกสาร'
                  },
                  {
                    value: '4',
                    label: 'อื่นๆ'
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              label='รายละเอียดปัญหาที่พบ'
              name='details_problem'
              rules={[{ required: true, message: 'กรุณากรอกรายละเอียดปัญหาที่พบ' }]}
              tooltip={{
                title: 'โปรดระบุข้อมูลให้ครบถ้วน เช่น ชื่อ One mail ผู้ใช้งาน เลขที่เอกสาร',
                icon: <InfoCircleOutlined />,
              }}
            >
              <TextArea
                placeholder="รายละเอียดปัญหาที่พบ"
                autoSize={{
                  minRows: 4,
                  maxRows: 6,
                }}
              />
            </Form.Item>
            <Form.Item
              label='แนบไฟล์เพิ่มเติม'
            >
              <Space 
              direction="vertical"
              style={{ width: '250px'}}
              >
                <Upload {...props}>
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Space>
            </Form.Item>
            <Form.Item
              style={{ textAlign: 'end' }}
            >
              <Space align='start' >
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: '#182F59',
                    },
                  }}
                >
                  <Button htmlType='submit' style={{ width: '100px' }} type='primary' shape='round' >ส่ง</Button>
                </ConfigProvider>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: '#AEAEAE',
                    },
                  }}
                >
                  <Button htmlType='button' style={{ width: '100px' }} type='default' shape='round' href="/follow-up" >ย้อนกลับ</Button>
                </ConfigProvider>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Flex>
  );
};

export default RequestPage;