import React from 'react';
import { Space, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const stylesProfile = {
    borderRadius: '10px',
    backgroundColor: '#fff',
    padding: '0px 20px 0px 20px',
    boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.1)',
    textAlign: 'start',
    margin: '20px 20px 5px 20px',
}

const ProfileUser = () => {
    return (
        <div style={stylesProfile}>
            <Space>
                <Avatar size='middle' icon={<UserOutlined />} />
                <p style={{ fontSize: '16px' }}>สวัสดี, คุณเดโม่ ดิจิทัลโฟล </p>
            </Space>
        </div>
    )
}

export default ProfileUser;