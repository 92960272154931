import React from 'react';

import {
  Space,
  Button,
  Flex,
  ConfigProvider
} from 'antd';

import CardTicket from '../components/cardTicket';
import ProfileUser from '../components/profileUser';
import { PlusOutlined } from '@ant-design/icons';

const stylesContent = {
  borderRadius: '10px',
  backgroundColor: '#fff',
  padding: '15px 30px 30px',
  boxShadow: '10px 5px 10px rgba(0, 0, 0, 0.1)',
  margin: '5px 20px 20px 20px',
}

const data = [
  {
    message: 'ปัญหาการเข้าสู่ระบบ',
    detailTicket: 'ทดสอบทดสอบทดสอบทดสอบทดสอบทดสอบ',
    ticketNo: '240101001',
    updateTime: '01/01/2024, 10:10',
    statusTicket: 'Open'
  },
  {
    message: 'ปัญหาการสร้างเอกสาร ',
    detailTicket: 'เอกสารเอกสารเอกสารเอกสารเอกสารเอกสารเอกสาร',
    ticketNo: '240101002',
    updateTime: '01/01/2024, 00:15',
    statusTicket: 'In progress'
  },
  {
    message: 'ปัญหาการลงนามเอกสาร',
    detailTicket: 'ปัญหาปัญหาปัญหาปัญหาปัญหาปัญหาปัญหาปัญหาปัญหาปัญหาปัญหา',
    ticketNo: '231231004',
    updateTime: '01/01/2024, 12:50',
    statusTicket: 'Resolve'
  },
  {
    message: 'ปัญหาการเข้าสู่ระบบ',
    detailTicket: 'ระบบระบบระบบระบบระบบระบบระบบระบบระบบระบบระบบระบบระบบระบบ',
    ticketNo: '231101001',
    updateTime: '01/11/2023, 15:15',
    statusTicket: 'Closed'
  },
  {
    message: 'อื่นๆ',
    detailTicket: 'ลงนามลงนามลงนามลงนามลงนามลงนามลงนามลงนามลงนามลงนาม',
    ticketNo: '230818027',
    updateTime: '18/08/2023, 09:39',
    statusTicket: 'Closed'
  },
]

const FollowPage = () => {
  return (
    <Flex justify='center'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'stretch',
          overflow: 'hidden',
          flexDirection: 'column',
          width: '600px',
        }}
      >
        <ProfileUser/>
        <div style={stylesContent}>
          <h4>ติดตามการแจ้งปัญหา</h4>
          <Space
            direction='vertical'
            style={{
              width: '100%',
              alignItems: 'stretch',
              maxHeight: '480px',
              overflowY: 'auto',
              scrollbarWidth: 'thin'
            }}
          >
            {data.map((item) => {
              return <CardTicket {...item} />
            })}
            {/* <CardTicket />
            <Card title='Card' size='small'>
              <p>Card content</p>
            </Card> */}
          </Space>
          <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: '#182F59',
                },
              }}
            >
              <Button style={{ width: '80px' }} type='primary' shape='round' icon={<PlusOutlined />} href='/report-problem' />
            </ConfigProvider>
          </div>
        </div>
      </div>
    </Flex>
  );
};

export default FollowPage;