import React from 'react';
import { Button, Form, Input, ConfigProvider, Checkbox, Space } from 'antd';

const stylesForm = {
    borderRadius: '10px',
    backgroundColor: '#fff',
    padding: '15px 30px 20px 30px',
    // maxWidth: 'fit-content',
    minWidth: 'fit-content',
    width: '500px',
    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.2)',
    margin: '20px',
}

const RegisterPage = () => {
    const onFinish = (values) => {
        console.log('Success:', values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'stretch',
                overflow: 'hidden'
            }}
        >
            <div style={stylesForm}>
                <div style={{ textAlign: 'center' }}>
                    <img src='Logo_DigitalFlow.png' alt='Logo' style={{ width: 100, height: 100, background: '#fff' }} />
                    <h2>ลงทะเบียน</h2>
                    <h4>เพื่อให้เราบริการได้รวดเร็วยิ่งขึ้น</h4>
                </div>
                <Form
                    layout='vertical'
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label='ชื่อ - นามสกุล'
                        name='fullname'
                        rules={[{ required: true, message: 'กรุณากรอกชื่อและนามสกุล!' }]}
                    >
                        <Input placeholder='ชื่อ - นามสกุล' />
                    </Form.Item>
                    <Form.Item
                        label='เบอร์โทรศัพท์ ที่สามารถติดต่อได้'
                        name='phone'
                        rules={[{ required: true, message: 'กรุณากรอกเบอร์โทรศัพท์!' }]}
                    >
                        <Input placeholder='0987654321' />
                    </Form.Item>
                    <Form.Item
                        label='อีเมล'
                        name='email'
                        rules={[{ required: true, type: 'email', message: 'กรุณากรอกอีเมลที่ถูกต้อง!' }]}
                    >
                        <Input placeholder='email@example.com' />
                    </Form.Item>
                    <Form.Item
                        label='บริษัท/องค์กร'
                        name='company'
                        rules={[{ required: true, message: 'กรุณากรอกชื่อบริษัทหรือองค์กร!' }]}
                    >
                        <Input placeholder='บริษัท/องค์กร' />
                    </Form.Item>
                    <Form.Item
                        name='privacyPolicy'
                        valuePropName='checked'
                        rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject('คุณต้องยอมรับนโยบายความเป็นส่วนตัว') }]}
                    >
                        <Checkbox>นโยบายคุ้มครองข้อมูลส่วนบุคคล</Checkbox>
                    </Form.Item>
                    <Form.Item
                        style={{ textAlign: 'end' }}
                    >
                        <Space align='start'>
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorPrimary: '#182F59',
                                    },
                                }}
                            >
                                <Button htmlType='submit' style={{ width: '100px' }} type='primary' shape='round' >ลงทะเบียน</Button>
                            </ConfigProvider>
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorPrimary: '#AEAEAE',
                                    },
                                }}
                            >
                                <Button htmlType='button' style={{ width: '100px' }} type='default' shape='round' >ย้อนกลับ</Button>
                            </ConfigProvider>

                        </Space>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default RegisterPage;